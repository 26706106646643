.img {
  height: 100px;
  object-fit: contain;
}
.container {
  height: 150px;
}
@media (max-width: 768px) {
  .container {
    margin-top: 50px;
  }
}
