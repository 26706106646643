@font-face {
  font-family: 'FactExpanded';
  src: url('./fonts/Fact-Expanded.woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FactExpanded';
  src: url('./fonts/Fact-ExpandedMedium.woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FactExpanded';
  src: url('./fonts/Fact-ExpandedBlack.woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Xolonium-Bold';
  src: url('./fonts/Xolonium-Bold.otf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Xolonium-Regular';
  src: url('./fonts/Xolonium-Regular.otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.promo-default {
  position: relative;
  display: flex;
  height: 850px;
  max-height: 1200px;
}

.promo-default__background {
  position: absolute;
  left: -320px;
  width: 1500px;
  height: 790px;

  background: url(./images/background.png) top center no-repeat;
  background-size: contain;
}

.promo-default__wheel {
  position: absolute;
  top: 30px;
  right: 0;
  width: 55vw;
  height: 100%;

  background: url(./images/wheel.png) top center no-repeat;
  background-size: contain;
}

.promo-default__title-button {
  margin: 0 4%;
  z-index: 1;
}
.promo-default__line {
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
}
.promo-default__subtitle .promo-default__title-line {
  margin-bottom: 10px;
}
.promo-default__title {
  padding-top: 50px;
  font-family: 'FactExpanded', 'Fact', sans-serif;
  font-size: 2.2vw;
  font-weight: 500;

  color: #ffffff;

  line-height: 1.1;
}
.selectedText {
  font-family: 'Xolonium-Regular', 'Xolonium-Bold', 'Fact', sans-serif;
  font-size: 30px;
}
.promo-default__subtitle {
  padding-top: 100px;
  font-family: 'FactExpanded', 'Fact', sans-serif;

  font-size: 2.2vw;
  font-weight: 500;

  color: #ffffff;

  line-height: 1.1;
}
.promo-default__description {
  padding-top: 30px;
  font-family: 'FactExpanded', 'Fact', sans-serif;
  font-size: 2vw;
  font-weight: 700;

  color: #ffffff;

  line-height: 1.3;
}

.promo-default__button {
  min-width: 180px;
  height: 48px;
  margin: 40px 0;
  padding: 0 40px;

  font-size: 14px;
  font-weight: 400;
  color: #fe5220;

  border: 1px solid #ffffff;
  border-radius: 25px;
  background-color: #ffffff;

  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.3);

  letter-spacing: 0.05em;
  line-height: 22px;
  text-transform: uppercase;

  transition: 0.5s;
  /* z-index: 20; */
}

.promo-default__button:hover {
  color: #ffffff;

  background-color: transparent;
}

@media screen and (min-width: 1597px) {
  .promo-default__subtitle {
    font-size: 1.8vw;
  }
}

@media screen and (max-width: 1399px) {
  .promo-default {
    height: 610px;
  }

  .promo-default__background {
    width: 1150px;
    left: -320px;
  }
}

@media screen and (max-width: 989px) {
  .promo-default {
    padding-top: 10%;

    background: linear-gradient(45deg, #fe4019 25%, #ff953d);
  }

  .promo-default__background {
    display: none;
    /* left: 0;
        top: 0;
        width: 100%;
        height: 100%; */

    /* background: linear-gradient(45deg,#fe4019 25%,#ff953d); */
  }
  .promo-default__line {
    margin-left: 25px;
  }
  .promo-default__title {
    padding: 0 25px;
    font-size: 3vw;
  }

  .promo-default__wheel {
    width: 75vw;
    margin-top: 10%;
  }
  .promo-default__subtitle {
    padding: 0;
    margin: 20px 0 20px 25px;
  }
  .promo-default__button {
    margin: 40px 25px;
    padding: 0 40px;
  }
  .promo-default__description {
    margin: 0px 25px;
    padding: 0;
    font-size: 3vw;
  }
}
@media screen and (max-width: 639px) and (max-height: 639px) {
  .promo-default {
    flex-direction: column;
    height: 60vh;
  }
}
@media screen and (max-width: 639px) {
  .promo-default {
    flex-direction: column;
    height: 90vh;
  }

  .promo-default__title-button {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */

    height: 100%;
  }
  .promo-default__line {
    margin-left: 0;
    margin-top: 0px;
  }
  .promo-default__subtitle {
    font-size: 3.5vw;
    margin-top: 10px;
  }
  .promo-default__title {
    margin: 65% auto 0;
    font-size: 28px;
    text-align: center;
  }
  .promo-default__description {
    margin: 10px auto 0;
    padding: 0;
    text-align: center;
    font-size: 28px;
  }
  .promo-default__wheel {
    width: 100%;
    transform: scaleX(-100%);
    top: 30px;
  }
}

@media screen and (max-width: 469px) {
  .promo-default {
    height: 80vh;
  }
  .promo-default__title {
    margin: 80% auto 0;
  }
  .promo-default__wheel {
    margin-top: 15%;
  }
}

@media screen and (max-width: 419px) {
  .promo-default__title {
  }
}

@media screen and (max-width: 359px) {
  .promo-default__title {
  }
}

@media screen and (max-width: 319px) {
  .promo-default__title {
    font-size: 32px;
  }
}
