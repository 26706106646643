.promo-list-main {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 48px;
  background: url(./1920.jpg) no-repeat right center/cover;
  border-radius: 12px;
  min-height: 400px;
  overflow: hidden;
}

@media (max-width: 1400px) {
  .promo-list-main {
    background: url(./1280.jpg) no-repeat right center/cover;
    margin-top: 32px;
  }
}

@media (max-width: 800px) {
  .promo-list-main {
    background: url(./768.jpg) no-repeat right center/cover;
    margin-top: 24px;
    min-height: 280px;
  }
}

@media (max-width: 600px) {
  .promo-list-main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background: url(./360.jpg) no-repeat center bottom/cover;
    margin-top: 16px;
    min-height: 420px;
  }
}

.promo-list-main-link {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 0;
}

.promo-list-main-inner {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 40px 80px;
  max-width: 735px;
  box-sizing: border-box;
  flex-grow: 1;
  z-index: 1;
}

@media (max-width: 800px) {
  .promo-list-main-inner {
    padding: 48px 40px;
    max-width: 340px;
  }
}

@media (max-width: 800px) {
  .promo-list-main-inner {
    padding: 12px;
    max-width: initial;
  }
}

.promo-list-main-header {
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;
  color: #161b1f;
  flex-grow: 1;
  margin-bottom: 32px;
  text-shadow: 2px 1px 6px rgb(253 136 7);
}

@media (max-width: 800px) {
  .promo-list-main-header {
    font-size: 24px;
    line-height: 28px;
  }
}
