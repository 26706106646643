.contacts {
  @media (min-width: 768px) {
    display: flex;
  }
}
.block {
  @media (min-width: 768px) {
    padding: 1rem;
    margin-right: 0.5rem;
    border-radius: 0.5rem;
    width: 50%;
    color: #ffffff;
    background-color: #000000;
  }
}
.title {
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: #ffffff;
}
.subtitle {
  margin-bottom: 1rem;
}
.maps {
  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 0.5rem;
    width: 50%;
  }
}
.mapsContent {
  margin-bottom: 0.5rem;
  background-color: #e5e7eb;

  @media (min-width: 768px) {
    margin-bottom: 0;
    border-radius: 0.5rem;
    height: auto;
  }
}
